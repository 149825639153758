import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
// import PageHero from "../components/template-partials/page-hero"
import FooterCrown from "../components/template-partials/footer-crown"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleLeft, faHome } from "@fortawesome/free-solid-svg-icons"

const NotFoundPage = () => {
  return (
    <Layout route="four-oh-four">
      <Seo
        title="Page Not Found"
        description="The page you’re looking for on SuspectSM.com does not exist. Please return to the homepage for
additional information."
      />

      <div className="row lg_mt-3 mt-1 align-center medium-align-left">
        <div className="columns small-12 large-9">
          <h1 className="color--purple text--watch-quinn">
            Sorry, the page you are looking for cannot be found.
          </h1>
          <p className="lead color--purple lg_mt-2 mt-2">
            The page you are looking for cannot be found or is temporarily
            unavailable.
          </p>
        </div>
      </div>

      <div className="row lg_mt-2 lg_mb-7 mb-7 align-center medium-align-left">
        <div className="columns small-12 medium-shrink">
          <a
            href="javascript:history.back()"
            className="btn btn--outline btn--small-fw btn--icon-reverse uppercase mt-1"
            to="/"
          >
            <FontAwesomeIcon className="color--powder" icon={faAngleLeft} />{" "}
            Back TO PREVIOUS PAGE
          </a>
        </div>
        <div className="columns small-12 medium-shrink">
          <Link
            to="/"
            className="btn btn--outline btn--small-fw btn--icon uppercase mt-1"
          >
            Go Home <FontAwesomeIcon className="color--powder" icon={faHome} />
          </Link>
        </div>
      </div>

      <FooterCrown type="home" />
    </Layout>
  )
}

export default NotFoundPage
